import $ from 'jquery'

$('.toggle-search').on('click', function (e) {
    $('#search-bar').toggleClass('toggled')
    e.preventDefault()
});
$(document).on('click', function (e) {
    if ($(e.target).parents('#search-bar').length == 0)
        $('#search-bar').removeClass('toggled')
})
