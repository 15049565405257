import $ from 'jquery';

$(() => {
    // alert('loaded')
    $('.suggested_seminars, .suggested_conferences, .conferences, .elearning, .trainings-list').on('click', '.add-to-cart-from-panel', function (e) {
        openPopin($(this))
        e.preventDefault()
    })
    $('.suggested_seminars, .suggested_conferences, .conferences, .elearning, .trainings-list').on('click', '.popin .close', function(){
        closePopin($(this))
    })
})

function openPopin($elem) {
    $('#item-overlay').addClass('open')
    $elem.closest('.item').find('.popin').addClass('open')
    $('#item-overlay').on('click', function() {
        closePopin($elem)
    })
}

function closePopin($elem) {
    $elem.closest('.item').find('.popin').removeClass('open')
    $('#item-overlay').removeClass('open');
    $('#item-overlay').off('click');
}
