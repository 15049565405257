
import $ from 'jquery';


const resizeFooterLogo = () => {
    // resize footer logo : same size as the header logo
    $('#footer-logo').width($('#logo').width());
}

$('.open-savoir').on('click', function (e) {
    $('.savoir').toggle()
    e.preventDefault();
})



$('.toggle-show').click(function () {
    $($(this).attr('for')).slideDown();
});
$('.toggle-hide').click(function () {
    $($(this).attr('for')).slideUp();
});

$(document).ready(() => {
    if ($('.anchor[name="start"]').length) {
        $([document.documentElement, document.body]).animate({
            scrollTop: $('.anchor[name="start"]').offset().top
        }, 100);
    }

    $(window).resize(resizeFooterLogo)
    resizeFooterLogo();
})

