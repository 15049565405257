import $ from 'jquery';
// import 'jquery-sticky'

// $('#header').sticky({
// })


$('.btn-navbar').on('click', function(){
    $('.nav-collapse').toggleClass('collapse')
})

let timing;

$('.barre_login .account_name').on('mouseover', function(){
    clearTimeout(timing)
    $('#user-account-menu-wrapper').show();
})


$('.barre_login_wrapper').on('mouseleave', function(){
    timing = setTimeout(() => {
        $('#user-account-menu-wrapper').hide();
    }, 500)
})


function onResize() {
    if($( window ).width() <= 720){
        $('.nav-collapse').append($('.barre_login_wrapper'))
        $('.nav-collapse').append($('#search-bar'))
    }
    else{
        $('.contact_phone').before($('.barre_login_wrapper'))
        $('.contact_phone').after($('#search-bar'))
    }
}
$(window).resize(onResize)
onResize()