<template>
  <div class="container trainings-list">
      <div class="row justify-content-center" v-html="results"></div>
      <div v-if="loading" class="loading"></div>
      <h3 v-if="!loading && !results" style="text-align:center; margin:100px 0">Aucune formation pour le moment</h3>
  </div>
</template>

<script>
import axios from "axios";
import $ from 'jquery'

export default {
  name: "Trainings",

  data() {
    return {
      loading: false,
      results: "",
      start: 0,
      no_more: false,
      course_id: null,
      domain_id: null
    };
  },
  methods: {
    async fetchTainings() {
      
      if (this.loading || this.no_more) return;
      this.loading = true;
      await axios.get(`/shop/get_seminars`, {params:{start:this.start, course_id: this.course_id, domain_id: this.domain_id}}).then((response) => {
        this.results += $.trim(response.data.html.replace('\n', ''))
        this.start = response.data.next
        this.no_more = response.data.no_more
      });
      this.loading = false;
    },

    scroll() {
      window.onscroll = () => {
        let footer = document.querySelector('footer');
        let footerPosition = footer.getBoundingClientRect();
        
        if(footerPosition.top < window.innerHeight) {
          this.fetchTainings();
        }
      };
    },
  },
  beforeMount() {
    this.course_id = $("[name='course_id']").val()
    this.domain_id = $("[name='domain_id']").val()
    this.fetchTainings();
  },
  mounted() {
    this.scroll();
  },
};
</script>

<style lang="less" scoped>
.loading {
  width: 200px;
  height: 150px;
  margin: auto;
  background-image: url("/static/img/spinner.gif");
  background-size: contain;
  background-repeat: no-repeat;
}
</style>