import $ from 'jquery'

// on domain select, show courses
// $('.course-menu').on('click', function () {
//     let domainClass = $(this).data('for')
//     $('.course-submenu').hide()
//     $('#' + domainClass).show()
//     $([document.documentElement, document.body]).animate({
//         scrollTop: $('.course-submenu:visible').offset().top - 82
//     }, 100);
// })

// // preselect active domain
if ($('.course-submenu>li.active').length) {
    let domainClass = $('.course-submenu>li.active').first().data('for')
    $('.' + domainClass).show()
}

if ($('.course-menu.active').length) {
    let coursesClass = $('.course-menu.active').first().data('for')
    $('#' + coursesClass).show()
}
