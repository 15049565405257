import 'slick-slider';

import $ from 'jquery';

$(document).ready(() => {
    $('#slider').slick({
        dots: false,
        slidesToShow: 1,
        infinite: true,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 6000
    });


    $('.trombi').slick({
        dots: false,
        slidesToShow: 4,
        infinite: true,
        slidesToScroll: 4,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 6000,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              }
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            },
        ]
    })
})